import React, { Component } from "react";
import BigProjectsData from "../data/BigProjectsData";
import "../styles/Projects.sass";

import ProjectsHeader from "./ProjectsHeader";
class Projects extends Component {
  render() {
    return (
      <div className="Projects" id="project">
        <div className="sm-container">
          <ProjectsHeader />
          {BigProjectsData.map((curr) => (
            <div
              className="Project-box wow fadeIn"
              data-wow-delay=".8s"
              key={curr.name}
            >
              <div className="left-side">
                <h1 className="project-name lg-hidden">
                  {curr.name}{" "}
                  {curr.onDevelopment && (
                    <span className="on-development">On development</span>
                  )}
                  {curr.currentJob && (
                    <span className="current-job">Current job</span>
                  )}
                </h1>
                {curr.image ? (
                  <a
                    href={curr.live || "/"}
                    className="live-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={curr.image} alt={curr.name} />
                  </a>
                ) : null}

                <div className="btn-container sm-hidden">
                  {curr.code.length > 0 ? (
                    <a
                      href={curr.code}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="code-btn codeBtnEnabled"
                    >
                      <i className="fab fa-github"></i>Code
                    </a>
                  ) : (
                    <div className="code-btn codeBtnDisabled">
                      <i className="fab fa-github"></i>Code
                    </div>
                  )}
                  <a
                    href={curr.live || "/"}
                    className="live-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fas fa-link"></i>Live
                  </a>
                </div>
              </div>
              <div className="right-side">
                <h1 className="project-name sm-hidden">
                  {curr.name}{" "}
                  {curr.onDevelopment && <span className="on-development">On development</span>}
                  {curr.currentJob && (
                    <span className="current-job">Current job</span>
                  )}
                </h1>
                <p className="description">{curr.description}</p>
                {curr.techs_used ? (
                  <div className="tech-used flex-left">
                    {curr.techs_used.map((tech) => (
                      <p key={tech}>{tech}</p>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="btn-container lg-hidden">
                {curr.code.length > 0 ? (
                  <a
                    href={curr.code}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="code-btn codeBtnEnabled"
                  >
                    <i className="fab fa-github"></i>Code
                  </a>
                ) : (
                  <div className="code-btn codeBtnDisabled">
                    <i className="fab fa-github"></i>Code
                  </div>
                )}
                <a
                  href={curr.live || "/"}
                  className="live-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-link"></i>Live
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Projects;

const data =  [
    {
        id: 1,
        contact: 'Email',
        hideIcon: true,
        target: '_blank',
        href: 'mailto:kevinmontero.dev@gmail.com',
        className: 'fas fa-envelope'
    },
    {
        id: 2,
        contact: 'Phone',
        hideIcon: false,
        target: '_self',
        phoneNumber: '(863) 777-9366‬',
        href: 'tel:1-863-777-9366‬',
        className: 'fas fa-phone-alt'
    },
    {
        id: 3,
        contact: 'LinkedIn',
        hideIcon: true,
        target: '_blank',
        href: 'https://www.linkedin.com/in/kevin-montero/',
        className: 'fab fa-linkedin-in'
    },
    {
        id: 4,
        contact: 'GitHub',
        hideIcon: true,
        target: '_blank',
        href: 'https://github.com/Kmonterohenriquez',
        className: 'fab fa-github'
    }
]
export default data
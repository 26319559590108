import React from 'react';
import '../styles/Footer.sass'
const Footer = () => {
	var currentDate = new Date();
	var currentYear = currentDate.getFullYear();
	return (
		<footer className='Footer'>
			<p>Designed and Developed by Kevin Montero © {currentYear}</p>
		</footer>
	);
};

export default Footer;

import React, { useEffect, useState } from "react";
import logo from "../img/my_logo.png";
import "../styles/Nav.sass";
import { Link } from "react-scroll";

const Nav = ({ menuToggle, menuHandler }) => {
  // const icon  = isDarkMode ? 'fa-sun yellow-icon' : 'fa-moon grey-icon';
  // const backgroundMobileMenu = isDarkMode? "black-bg": "white-bg"
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    const shouldAllowScroll = windowWidth >= 700;
    if (shouldAllowScroll) {
      document.body.style.overflow = "auto";
    } else if (menuToggle) {
      // If the menu is open, disable scrolling
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0);
    } else {
      // If the menu is closed, enable scrolling
      document.body.style.overflow = "auto";
    }

    // Clean up function
    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("resize", handleResize);
    };
  }, [menuToggle, windowWidth]);

  const menuIcon = menuToggle ? "fas fa-times" : "fas fa-bars";
  return (
    <header className="Nav">
      <div className="Nav-container lg-container">
        <div className="logo">
          <img src={logo} alt="" />
        </div>

        {menuToggle && (
          <nav className="menu-nav white-bg">
            <ul>
              <li>
                <Link
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={() => menuHandler()}
                  className="nav-link"
                >
                  About me
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="project"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="nav-link"
                  onClick={() => menuHandler()}
                >
                  Projects
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  className="nav-link"
                  duration={500}
                  onClick={() => menuHandler()}
                >
                  Contact me
                </Link>
              </li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1f1_AQyiWqw7E3UYmk0Wp5D7PVeNbCPqI/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="resume-btn"
                  onClick={() => menuHandler()}
                >
                  Resume
                </a>
              </li>
            </ul>
          </nav>
        )}

        <nav className="navbar wow fadeIn">
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            About me
          </Link>
          <Link
            activeClass="active"
            to="project"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Projects
          </Link>
          <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Contact me
          </Link>
        </nav>
        <div className="menu-icon">
          <i onClick={() => menuHandler()} className={menuIcon}></i>
        </div>
      </div>
    </header>
  );
};

export default Nav;

import React from 'react';
import skills from '../data/Skills-data';
import '../styles/Skills.sass';

const Skills = () => {
  return (
    <div className='Skills'>
      <div className='Skills-container sm-container'>
        <h1 className='wow fadeInDown' data-wow-delay='.5s'>
          Skills
        </h1>
        <p className='sub-title'>
          I always enjoy learning new things. Especially the technologies that
          make your company special. These are some of the technologies I have worked with:
        </p>
        <div className='grid-skills'>
          {skills.map((skill) => (
            <div className='skill-box' key={skill.skill}>
              <p>
                {skill.icon ? <i className={skill.className}></i> : <i className="fas fa-code"></i>}
                {skill.skill}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;

import React from "react";
import contactData from "../data/Contact-data";

const ContactBar = () => {
  return (
    <div className="Contact-bar">
      {contactData.map((contact) => (
        <a
          key={contact.id}
          id={contact.contact}
          href={contact.href}
          target={contact.target}
          without
          rel="noopener noreferrer"
          className={contact.hideIcon ? "hide-icon icon-box" : "icon-box"}
        >
          <i className={contact.className}></i>
        </a>
      ))}
      <div className="Contact-bar-line hide-icon"></div>
    </div>
  );
};

export default ContactBar;

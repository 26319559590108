import React from 'react';
import contactData from '../data/Contact-data';
import '../styles/Contact.sass';
// import MailMe from './MailMe';

const Contact = () => {
  return (
    <div className="Contact" id="contact">
      <div className="grid-contact container">
        <div className="Contact-left-side">
          <h2 className="wow fadeInDown" data-wow-delay=".5s">
            Connect with me:
          </h2>
          <p className="wow fadeInUp" data-wow-delay=".5s">
            Feel free to get in touch! I'm always open to discussing new
            projects, creative ideas, <br /> or opportunities to be part of your
            visions.
          </p>
          <div class="contact-icons-container">
            {contactData.map((contact) => (
              <a
                href={contact.href}
                target={contact.target}
                without
                rel="noopener noreferrer"
                id={contact.contact}
                className="icon-box"
                key={contact.id}
              >
                <i className={contact.className}></i>
              </a>
            ))}
          </div>
        </div>
        {/* <div className='Contact-right-side'>
          <MailMe />
        </div> */}
      </div>
    </div>
  );
};

export default Contact;

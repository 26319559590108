import React, { useState, useEffect } from "react";
import "./styles/App.sass";

import WOW from "wowjs";

// Components
import Nav from "./Components/Nav";
import Showcase from "./Components/Showcase";
import About from "./Components/About";
import Skills from "./Components/Skills";
import Projects from "./Components/Projects";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import ContactBar from "./Components/ContactBar";

const App = () => {
  useEffect(() => {
    new WOW.WOW({ live: false }).init();
  }, []);
  const [menuToggle, setMenuToggle] = useState(false);
  const [isDarkMode, setIsdarkMode] = useState(false);
  const menuHandler = () => {
    setMenuToggle(!menuToggle);
  };

  const darkModeToogle = () => {
    setIsdarkMode(!isDarkMode);
  };

  const shouldRenderAsDarkMode = isDarkMode ? "dark-mode" : "clear-mode";
  const useNoScroll = menuToggle ? "no-scroll" : "";
  return (
    <div className={`App ${useNoScroll} ${shouldRenderAsDarkMode}`}>
      <Nav
        menuHandler={menuHandler}
        menuToggle={menuToggle}
        darkModeToogle={darkModeToogle}
        isDarkMode={isDarkMode}
      />
      <Showcase isDarkMode={isDarkMode} />
      <Skills />
      <Projects isDarkMode={isDarkMode} />
      <About />
      <Contact />
      <Footer />
      <ContactBar />
    </div>
  );
};

export default App;

import React from 'react';
import me from '../img/me-2.jpeg';
import '../styles/About.sass';
import grayDots from '../img/gray-dots-full.png';
const About = () => {
  return (
    <div className='About' id='about'>
      <div className='About-container container'>
        <img
          className='wow fadeIn'
          data-wow-delay='.5s'
          data-wow-duration='2s'
          src={me}
          alt='Kevin Montero'
          id='myPicture'
        />
        <div className='info'>
          <h1 className='wow fadeInRight' data-wow-delay='.5s'>
            About
          </h1>
          <p
            className='wow fadeIn'
            data-wow-delay='.5s'
            data-wow-duration='1.5s'
          >
            Hello there! My name is Kevin, and I am a Full-Stack Web Developer.
            I enjoy spending my free time increasing my knowledge of web
            development and building new and challenging projects.
            <div className='gray-dot-container'>
              <img src={grayDots} alt='gray-dots' />
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;

const data = [
  {
    icon: true,
    className: "fab fa-react",
    skill: "React",
  },
  {
    icon: false,
    className: "",
    skill: "Next.js",
  },
  {
    icon: true,
    className: "fab fa-node-js",
    skill: "Node",
  },
  {
    icon: false,
    className: "",
    skill: "Express",
  },
  {
    icon: true,
    className: "fab fa-js-square",
    skill: "JavaScript",
  },
  {
    icon: false,
    className: "",
    skill: "TypeScript",
  },
  {
    icon: true,
    className: "fab fa-html5",
    skill: "HTML",
  },
  {
    icon: true,
    className: "fab fa-css3",
    skill: "CSS",
  },
  {
    icon: true,
    className: "fab fa-aws",
    skill: "AWS",
  },
  {
    icon: true,
    className: "fab fa-bootstrap",
    skill: "Bootstrap",
  },
  {
    icon: false,
    className: "",
    skill: "Material UI",
  },
  {
    icon: true,
    className: "fab fa-sass",
    skill: "Sass",
  },
  {
    icon: false,
    className: "",
    skill: "Resful API",
  },
  {
    icon: false,
    className: "",
    skill: "redux",
  },
  {
    icon: true,
    className: "fas fa-database",
    skill: "PostgreSQL",
  },
  {
    icon: true,
    className: "fas fa-database",
    skill: "SQL",
  },
  {
    icon: true,
    className: "fas fa-database",
    skill: "MySQL",
  },
  {
    icon: true,
    className: "fas fa-database",
    skill: "MongoDB",
  },
  {
    icon: true,
    className: "fab fa-python",
    skill: "Python",
  },
  {
    icon: true,
    className: "fab fa-git-alt",
    skill: "Git",
  },
  {
    icon: true,
    className: "fab fa-github",
    skill: "GitHub",
  },
  {
    icon: false,
    className: "",
    skill: "Webpack",
  },
  {
    icon: false,
    className: "",
    skill: "Postman",
  },
  {
    icon: false,
    className: "",
    skill: "Jest",
  }
];

export default data